.pick-product-wrapper.current {
    cursor: unset;
    border: 1px dashed rgba(255, 255, 255, 0.80);
    height: unset;

    container-type: inline-size;
    container-name: pick-product-wrapper-current;
}
.pick-product-wrapper.current:hover {
    border-color: rgba(255, 255, 255, 0.80);
}

.pick-product-wrapper.current .pick-product-upper {
    justify-content: center;
}

.pick-product-wrapper.current .pick-product-upper .pick-current-product-title{
    color: rgba(255, 255, 255, 0.80);
    text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.32);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
}

.pick-product-wrapper.current .pick-product-upper .pick-product-title {
    color: rgba(255, 255, 255, 0.65);
}

.pick-product-wrapper.current .pick-product-lower .pick-product-usps {
    color: rgba(255, 255, 255, 0.80);
}

.pick-product-wrapper.current .pick-product-lower {
    height: 100%;
}
.pick-product-wrapper.current .pick-product-lower .pick-product-usps{
    height: 100%;
}
.pick-product-wrapper.current .pick-product-lower .pick-product-usps ul {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    flex-wrap: wrap;
    gap: 16px 32px;
    flex-direction: column;

    padding: 0;
    list-style-position: inside;
}

.pick-product-wrapper.current .pick-product-lower .pick-product-usps ul li {
    min-width: 220px;
    max-width: 380px;
    text-wrap: pretty;
    align-self: flex-start;
}

@container pick-product-wrapper-current (min-width: 732px) {
    .pick-product-wrapper.current .pick-product-lower {
        max-height: 151px;
    }

    .pick-product-wrapper.current .pick-product-lower .pick-product-usps ul {
        align-content: center;
    }

    .pick-product-wrapper.current .pick-product-lower .pick-product-usps ul:has(:nth-child(5)) li {
        width: calc(50% - 16px);
    }
}